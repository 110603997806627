// import Vue from 'vue'
// import Vuex from 'vuex'
//
//
// import state from "./state"
// Vue.use(Vuex)
//
// import { createStore } from 'vuex'
// export const store = createStore({
//     state () {
//         return {
//             count: 1,
//         }
//     }
// })
//
// export default new Vuex.Store({
//     state,
//     // actions,
// })
import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            ps_value: [], // Retrieve from localStorage or use an empty array
        };
    },
    mutations: {
        ownerCompanyName(state, newArray) {
            state.ps_value = newArray;
        },
    },
    actions: {
        ownerCompanyName(context, newArray) {
            context.commit('ownerCompanyName', newArray);
        },
    },
    getters: {

    },
});

export default store;




