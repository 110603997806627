import {createRouter,createWebHashHistory} from "vue-router";

const routes=[
    { path:"/",
        name:"Login_page" ,
        component: () => import('@/components/auth/Login')
    },
    { path:"/Preview_Program/:id/:height/:width",
        name:"Preview_Program" ,
        component: () => import('@/views/program/Preview_Program'),
    },
    { path:"/display_boards",
        name:"display_boards" ,
        component: () => import('@/views/digital_board/Display_Boards'),
    },
    { path:"/register",
        name:"Register_page" ,
        component: () => import('@/components/auth/Register')
    },
    { path:"/Delete_popup",
        name:"Delete_popup" ,
        component: () => import('@/views/popup/Delete_popup'),
    },
    { path:"/test_code",
        name:"test_code" ,
        component: () => import('@/views/test_code'),
    },
    { path:"/base",
        name:"Base_Dashboard" ,
        component: () => import('@/layouts/Base_Dashboard'),
        children:[
            { path:"/design_konva",
                name:"design_konva" ,
                component: () => import('@/views/design_konva'),
            },
            { path:"/setting",
                name:"setting_page" ,
                component: () => import('@/views/setting/Setting'),
                children:[
                    { path:"/User_Management",
                        name:"User_Management" ,
                        component: () => import('@/views/setting/User_Management')
                    },
                    { path:"/edit_user_info/:id",
                        name:"Edit_User_info" ,
                        component: () => import('@/views/setting/Edit_User_info')
                    },
                    { path:"/add_User",
                        name:"AddUser" ,
                        component: () => import('@/views/setting/AddUser')
                    },
                    { path:"/license",
                        name:"License_page" ,
                        component: () => import('@/views/setting/License')
                    },
                    { path:"/purchase_License",
                        name:"purchase_License" ,
                        component: () => import('@/views/setting/Purchase_License')
                    },
                    { path:"/Management_License",
                        name:"Management_License" ,
                        component: () => import('@/views/setting/Management_License')
                    },
                    { path:"/AssignUsers",
                        name:"AssignUsers" ,
                        component: () => import('@/views/setting/AssignUsers')
                    },
                ]
            },
            { path:"/program",
                name:"Program_page" ,
                component: () => import('@/views/program/Program'),
            },
            { path:"/design_program/:id/:height/:width",
                name:"Design_Program" ,
                component: () => import('@/views/program/Design_Program'),
            },
            { path:"/boards",
                name:"BoardsPage" ,
                component: () => import('@/views/boards/Boards'),
            },
            { path:"/Send_data",
                name:"Send_data" ,
                component: () => import('@/views/send/Send_data'),
            },
            { path:"/Home_Page",
                name:"Home_Page" ,
                component: () => import('@/views/dashboard/Home_Page'),
            },
            { path:"/Report_info",
                name:"Report_info" ,
                component: () => import('@/views/report/Report_info'),
            },
        ]
    },
]

const router=createRouter({
    history:createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const userInfo = localStorage.getItem('token') ? localStorage.getItem('token') : ''
    if (userInfo !== '' && to.path === '/' && from.path === '/') {
        next({ path: '/Home_Page' })
        localStorage.setItem('status','dashboard')
    }
    else if (userInfo !== '' && from.path !== '/' && to.path === '/') {
        next({ path: from.path })
    }
    else {
        next()
    }
})


export default router;