export default {
    'Login':{
        'login': 'ورود به حساب کاربری',
        'username': 'نام کاربری',
        'username_placeholder' : 'نام کاربری را وارد کنید',
        'password': 'رمزعبور',
        'password_placeholder' : 'رمزعبور را وارد کنید',
        'login_button': 'ورود' ,
        'register_button': 'ایجاد حساب',
        'register_title': 'ایجاد حساب کاربری',
        'firstname' : 'نام',
        'lastname' : 'نام خانوادگی',
        'firstname_placeholder' : 'نام را وارد کنید',
        'lastname_placeholder' : 'نام خانوادگی را وارد کنید',
        'mobile_number' : 'شماره موبایل',
        'email' : 'ایمیل',
        'mobile_number_placeholder' : 'شماره تلفن همراه را وارد کنید',
        'email_placeholder' : 'ایمیل را وارد کنید',
        'register': 'ایجاد',
        'login_link': 'ورود به حساب',
        'link_show_board':'نمایش تابلوهای ساینیج',
    },
    'Dashboard':{
        'company_name': 'VestaSignage',
        'profile': 'پروفایل',
        'exit': 'خروج',
        'welcome_signage': 'Welcome To Signage',
        'summery_detail': 'Free Digital Signage for everyone. Start using the free service right now. It\'s the world\'s most popular digital signage platform and it is 100% Ad free.',
    },
    'popup':{
        'close':'انصراف',
        'text_delete_popup':'آیا تمایل به حذف کردن دارید؟',
        'delete_text':'حذف کردن'
    },
    'send_data':{
        'select_boardName' :'نام تابلو را انتخاب کنید' ,
        'select_program' :'نام برنامه را انتخاب کنید' ,
        'show_sent_board':'نمایش' ,
        'order_table': 'ردیف',
        'time_date': 'تاریخ و زمان ثبت',
        'board_model': 'مدل تابلو',
        'program_name': 'نام برنامه',
        'sender': 'ارسال کننده',
        'title_send_data':'ارسال دیتا',
        'programs_name':': نام برنامه ها',
        'board_name':': نام تابلو',
        'send_data':'ارسال',
        'send_date_time':'تاریخ و زمان ارسال'
    },
    'boards':{
        'boards_list':'لیست تابلو ها',
        'search_box_placeholder':'جست و جو کنید. . .',
        'order':'ردیف',
        'board_name':'نام تابلو',
        'status':'وضعیت',
        'action':'اقدامات',
        'edit_icon':'ویرایش',
        'delete_icon':'حذف',
        'program_status':'وضعیت برنامه:',
        'sent':'ارسال شده',
        'time_sent': 'زمان ارسال :',
        'ip':'IP:',
        'serial':'َسریال تابلو',
        'available_programs':'برنامه های موجود برای تابلو',
        'title_icon_off': 'خاموش کردن',
        'title_icon_time_off':'زمان بندی روشن خاموش کردن',
        'title_icon_restart':'راه اندازی مجدد ',
        'title_icon_refresh':' تازه سازی ',
        'title_icon_network':'تنظیمات شبکه',
        'title_icon_location':'تنظیمات مکان و زمان',
        'title_icon_preview':'پیش نمایش',
        'insert_board':'افزودن تابلو' ,
        'popup_exit':'انصراف',
        'placeholder_board_name':'نام تابلو را وارد کنید"',
        'serial_number':'سریال تابلو:',
        'placeholder_serial_number':'سریال تابلو را وارد کنید',
        'board_model':'مدل تابلو:',
        'insert_new_board':'ایجاد تابلو جدید',
        'update_board':'ویرایش تابلو',
        'size_board':'سایز تابلو ها'
    },
    'programs':{
        'list_programs': 'لیست برنامه ها',
        'search_box':' جست و جو کنید. . .',
        'order':'ردیف',
        'program_name':'نام برنامه',
        'detail':'توضیحات',
        'board_model':'مدل تابلو',
        'action':'اقدامات',
        'edit': 'ویرایش',
        'delete':'حذف',
        'show_detail':'مشاهده جزئیات',
        'screen_name':'نام اسکرین ها',
        'time_duration':'مدت زمان پخش',
        'play_order':'ترتیب پخش',
        'placeholder_screen_name':'نام صفحه را وارد کنید',
        'placeholder_play_order':'ترتیب را وارد کنید',
        'placeholder_time_duration':'مدت زمان پخش را به ثانیه وارد کنید',
        'preview':'پیش نمایش',
        'edit_screen_content':'ویرایش کردن محتوا صفحه',
        'insert_program':'افزودن برنامه',
        'exit':'انصراف',
        'save': 'ذخیره',
        'insert':'افزودن',
        'insert_screen':'افزودن صفحه',
        'insert_template':'افزودن template',
        'screenName':'نام اسکرین:',
        'text_insert_screen':'در این حالت میتوانید فایل مورد نظر را برای اضافه کردن صفحه انتخاب کنید',
        'placeholder_program_name':'نام برنامه را وارد کنید',
        'placeholder_program_detail':'توضیحات مربوط به برنامه',
        'select_board_model':' نحوه تعیین مدل تابلو:',
        'related_board_model':'براساس مدل تابلو',
        'related_board_name':'براساس نام تابلو',
        'board_name':'نام تابلو:',
        'insert_new_program':'ایجاد برنامه جدید',
        'import':'داخل سازی',
        'export':'خارج سازی',
        'refresh':'تازه سازی',
    },
    'design_program':{
        'text':'متن',
        'newsTicker':'زیرنویس',
        'image':'عکس',
        'video':'فیلم',
        'website':'تارنما',
        'shape':'اشکال هندسی',
        'prayTimes':'اوقات شرعی',
        'stream':'استریم',
        'settings':'تنظیمات صفحه',
        'insert_text':'افزودن متن',
        'insert_subscribe':'افزودن زیرنویس',
        'direction_rtl':'جهت زیرنویس از راست به چپ',
        'direction_ltr':'جهت زیرنویس از چپ به راست',
        'placeholder_newsTicker':'متن زیرنویس را وارد کنید',
        'update_subscribe_text':'آپدیت متن زیرنویس',
        'font_style':'فونت استایل',
        'color_style':'رنگ',
        'font_color':'تغییر رنگ متن',
        'highlight_text':'هایلایت متن',
        'richBox_style':'ترازبندی',
        'align_left':'چپ چین',
        'align_center':'وسط چین',
        'align_right':'راست چین',
        'insert_image':'افزودن عکس',
        'insert_video':'افزودن ویدئو',
        'url_website':'آدرس سایت',
        'load_website':'نمایش تارنما',
        'placeholder_url_website':'آدرس سایت را وارد کنید',
        'change_color':'تغییر رنگ',
        'background_color':'رنگ پس زمینه',
        'border_color':'رنگ حاشیه',
        'prayTime_setting':'تنظیمات اوقات شرعی',
        'province':'استان',
        'city':'شهر',
        'show_location':'نمایش مشخصات مکان',
        'morning_time':'اذان صبح',
        'sunset_time':'غروب آفتاب',
        'sunrise_time':'طلوع آفتاب',
        'maghreb_time':'اذان مغرب',
        'noon_time':'اذان ظهر',
        'evening_time':'اذان عشا',
        'asr_time':'اذان عصر',
        'midnight_time':'نیمه شب',
        'insert_pray_time':'افزودن اوقات شرعی',
        'update_pray_time': 'به روز رسانی اوقات شرعی',
        'date_time':'تاریخ و زمان',
        'show_time':'نمایش زمان',
        'show_date':'نمایش تاریخ',
        'background_image':'عکس پس زمینه',
        'opacity':'وضوح پس زمینه',
        'change_layers':'تغییر لایه',
        'highest_layer': 'بالا ترین لایه',
        'lowest_layer': 'پایین ترین لایه',
        'bring_up_layer':'یک لایه بالاتر',
        'bring_down_layer':'یک لایه پایین تر',
        'magnify':'بزرگنمایی',
        'exist_object':'آبجکت های موجود',
        'duplicate':'duplicate',
        'save':'ذخیره',
        'delete_all':'حذف همه',
        'redo':'redo',
        'undo':'undo',
        'preview':'پیش نمایش',
        'media':'افزودن',
        'exit':'انصراف',
        'insert_media':'افزودن دیتاهای ذخیره شده',
        'insert_shop':'افزودن از فروشگاه',
        'delete_background':'حذف پس زمینه',
        'delete_object':'(delete:کلید میانبر) حذف آبجکت انتخاب شده',
        'duration':'مدت زمان پخش',
        'duration_placeholder':'مدت زمان پخش اسکرین به ثانیه'
    },
    'add_user':{
        'insert_user':'افزودن کاربر',
        'username':'نام کاربری:',
        'password':'رمزعبور:',
        'password_placeholder':'رمزعبور را وارد کنید',
        'username_placeholder': 'نام کاربری را وارد کنید',
        'name':'نام:',
        'name_placeholder': 'نام را وارد کنید',
        'last_name':'نام خانوادگی:',
        'last_name_placeholder': 'نام خانوادگی را وارد کنید',
        'number':'شماره تلفن همراه:',
        'number_placeholder': 'شماره تلفن همراه را وارد کنید',
        'email':'ایمیل:',
        'email_placeholder': 'ایمیل را وارد کنید',
        'expiration':'تاریخ اعتبار:',
        'date':'تاریخ',
        'status':'وضعیت',
        'access_board':'تابلوهای مجاز کاربر:',
        'permission':'سطح دسترسی',
        'save':'ذخیره'
    },
    'assign_user':{
        'no_option_select':'موردی یافت نشد',
        'insert_user':'اتصال کاربر',
        'username':'نام کاربری:',
        'username_placeholder': 'نام کاربری را وارد کنید',
        'user_permission':'دسترسی کاربران به تابلو',
        'users':'کاربران :',
        'user_placeholder':'کاربران را انتخاب کنید',
        'board':'تابلو ها :',
        'board_placeholder':' تابلو را انتخاب کنید',
        'save':'ذخیره',
        'connect_user':'اتصال',
        'list_user':'لیست کاربران',
        'list_license':'لیست لایسنس ها',
        'expireLicense':'تاریخ انقضا لایسنس',
        'license':'لایسنس ها',
        'edit_owner':'نام شرکت',
        'edit_license':'نام لایسنس',
        'license_key':'کلید لایسنس',
        'order':'ردیف',
        'usernames':'نام کاربری',
        'status':'وضعیت',
        'full_name':'نام و نام خانوادگی',
        'permission':' دسترسی تابلو ها',
        'actions':'اقدامات',
        'edit':'ویرایش',
        'delete':'حذف',
        'list_board':'لیست تابلو ها',
        'board_name':'نام تابلو',
        'serial_board':'سریال تابلو',
        'board_model':' مدل تابلو',
        'permissions':'دسترسی ها',
        'show_access_board':'نمایش دسترسی تابلو',
        'show_access_user':'نمایش دسترسی کاربر',
        'access_board':'دسترسی کاربران به تابلو',
        'access_user':'دسترسی کاربر',
        'mobile':'تلفن همراه',
        'popup_edit':{
            'exit':'انصراف',
            'user':'کاربر:',
            'board':'تابلو:',
            'board_placeholder':' تابلو را انتخاب کنید',
            'edit':'ویرایش',
            'user_placeholder':'کاربر را انتخاب کنید'
        }
    },
    'edit_user':{
        'edit_user':'ویرایش کاربر',
        'name':'نام:',
        'name_placeholder': 'نام را وارد کنید',
        'last_name':'نام خانوادگی:',
        'last_name_placeholder': 'نام خانوادگی را وارد کنید',
        'number':'شماره تلفن همراه:',
        'number_placeholder': 'شماره تلفن همراه را وارد کنید',
        'email':'ایمیل:',
        'email_placeholder': 'ایمیل را وارد کنید',
        'expiration':'تاریخ اعتبار:',
        'date':'تاریخ',
        'status':'وضعیت:',
        'access_board':'تابلوهای مجاز کاربر:',
        'permission':'سطح دسترسی:',
        'save':'ذخیره',
        'active':'فعال',
        'inactive':'غیرفعال',
        'cancel':'بازگشت'
    },
    'setting':{
        'users':'کاربران',
        'management_users':'مدیریت کاربران',
        'license':'لایسنس',
        'shop':'فروشگاه',
    },
    'user_management':{
        'exit':'انصراف',
        'user_info':'اطلاعات کاربر',
        'full_name':'نام و نام خانوادگی:',
        'username':'نام کاربری:',
        'mobile':'شماره تلفن همراه:',
        'email':'ایمیل:',
        'expiration':'تاریخ اعتبار:',
        'status':'وضعیت:',
        'access_board_user':'تابلوهای مجاز کاربر',
        'board':'تابلو:',
        'user_permissions':'سطح دسترسی کاربر',
        'order':'ردیف',
        'all':'همه',
        'active':'فعال',
        'inactive':'غیرفعال',
        'action':'اقدامات',
        'details':'جزئیات',
        'delete':'حذف',
        'edit':'ویرایش',
        'insert_users':'افزودن کاربر',

    },
    'license_customer':{
        'insert_license':'افزودن لایسنس',
        'customer_name': ':نام کسب و کار',
        'license':':لایسنس',
        'placeholder_customerNAme':'نام کسب و کار را وارد کنید',
        'placeholder_license':'لایسنس را وارد کنید',
        'save': 'ثبت' ,
        'details_license':'اطلاعات لایسنس',
        'title': 'عنوان',
        'status':'وضعیت',
    },
    'management_license':{
        'username':'نام کاربری خریدار',
        'title_management_license':'ایجاد لایسنس',
        'volume': 'حجم',
        'user_number':'تعداد کاربران',
        'board_number':'تعداد تابلو ها',
        'volume_placeholder':'حجم اختصاصی را به صورت گیگابایت وارد کنید',
        'user_number_placeholder':'تعداد کاربران را وارد کنید',
        'board_number_placeholder':'تعداد تابلوها را وارد کنید',
        'insert':'ایجاد',
        'order':'ردیف',
        'license':'لایسنس',
        'expiration':'تاریخ انقضا',
        'customer':'کسب و کار',
        'action':'اقدامات',
        'expiration_date':'تاریخ انقضا',
        'new_license':'لایسنس ایجاد شده :',
    },
    'purchase_License':{
        'purchase_licenses':'خرید لایسنس',
        'total_cost':'جمع کل',
        'purchase':'خرید',
        'unit':'تومان',
    },
}
