import { createApp } from 'vue'
import state from './store/state'
import store from './store/store'
import axios from 'axios'

axios.defaults.baseURL = state.serverBaseUrl + '/api'
axios.defaults.headers.common['Content-Type'] = 'Application/json'
axios.defaults.headers.common.Accept = 'Application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')


import router from './router.js'

import './assets/sass/index.sass'

import Notifications from '@kyvg/vue3-notification'

import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'

import html2canvas from 'html2canvas'

import { createI18n } from 'vue-i18n'
import fa from './i18n/fa'
import en from './i18n/en'

import vSelect from 'vue-select'

import 'boxicons/css/boxicons.css'

import { fabric } from 'fabric'
import VueKonva from 'vue-konva'
// import CryptoJS from 'crypto-js';

import App from './App.vue'

const app= createApp(App)
const i18n = createI18n({
    locale: 'fa',
    messages: {  fa,   en  }
})

// app.config.globalProperties.$CryptoJS = CryptoJS
// app.use(store)
app.use (router)
app.use(Notifications)
app.component('DatePicker', Vue3PersianDatetimePicker)
app.config.globalProperties.$html2canvas = html2canvas;
app.component('v-select', vSelect)
app.use(i18n)
app.config.globalProperties.$fabric = fabric
app.use(VueKonva);
app.use(store)
app.mount('#app')

