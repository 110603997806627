export default {
    'Login':{
        'login': 'Login',
        'username': 'Username',
        'username_placeholder' : 'Enter username',
        'password': 'Password',
        'password_placeholder' : 'Enter password',
        'login_button': 'Login' ,
        'register_button': 'Sign in',
        'register_title': 'Sign up',
        'firstname' : 'Firstname:',
        'lastname' : 'Lastname:',
        'firstname_placeholder' : 'Enter firstname',
        'lastname_placeholder' : 'Enter lastname',
        'mobile_number' : 'Mobile number:',
        'email' : 'Email:',
        'mobile_number_placeholder' : 'Enter mobile number',
        'email_placeholder' : 'Enter email',
        'register': 'create',
        'login_link': 'Login'
    },
    'Dashboard':{
        'company_name': 'VestaSignage',
        'profile': 'Profile',
        'exit': 'Exit',
        'welcome_signage': 'Welcome To Signage',
        'summery_detail': 'Free Digital Signage for everyone. Start using the free service right now. It\'s the world\'s most popular digital signage platform and it is 100% Ad free.',
    },
    'popup':{
        'close':'cancel',
        'text_delete_popup':'Are you sure you want to delete this ?',
        'delete_text':'delete'
    },
    'send_data':{
        'select_boardName' :'Enter board name' ,
        'show_sent_board':'display' ,
        'order_table': 'Order',
        'time_date': 'Time & Date',
        'board_model': 'Board model',
        'program_name': 'Program name',
        'sender': 'Sender',
        'title_send_data':'Send',
        'programs_name':'Programs name:',
        'board_name':'Board name:',
        'send_data':'send',
    },
    'boards':{
        'boards_list':'Boards List',
        'search_box_placeholder':'search . . .',
        'order':'Order',
        'board_name':'Board name',
        'status':'Status',
        'action':'Action',
        'edit_icon':'Edit',
        'delete_icon':'Delete',
        'program_status':'Program status',
        'sent':'Sent',
        'time_sent': 'Sent time:',
        'ip':'IP:',
        'serial':'َBoard serial:',
        'available_programs':'available programs',
        'title_icon_off': 'off',
        'title_icon_time_off':'timer off/on',
        'title_icon_restart':'restart ',
        'title_icon_refresh':'refresh',
        'title_icon_network':'network settings',
        'title_icon_location':'location settings',
        'title_icon_preview':'preview',
        'insert_board':'Insert board' ,
        'popup_exit':'cancel',
        'placeholder_board_name':'Enter board name',
        'serial_number':'Board serial:',
        'placeholder_serial_number':'Enter board serial number',
        'board_model':'Board model',
        'insert_new_board':'Insert New board',
        'update_board':'edit board',
    },
    'programs':{
        'list_programs': 'Programs list',
        'search_box':' search. . .',
        'order':'Order',
        'program_name':'Program name',
        'detail':'Details',
        'board_model':'Board model',
        'action':'action',
        'edit': 'edit',
        'delete':'delete',
        'show_detail':'details',
        'screen_name':'Screen name',
        'time_duration':'Duration of broadcast',
        'play_order':'Play order',
        'placeholder_screen_name':'Enter screen name',
        'placeholder_play_order':'Enter order',
        'placeholder_time_duration':'Enter duration of playing screen',
        'preview':'preview',
        'edit_screen_content':'Edit screen content',
        'insert_program':'Insert program',
        'exit':'cancel',
        'save': 'save',
        'insert':'Insert',
        'insert_screen':'Insert screen',
        'insert_template':'Insert template',
        'screenName':'Screen name:',
        'text_insert_screen':'In this case, you can select the desired file to add to the page',
        'placeholder_program_name':'Enter program name',
        'placeholder_program_detail':'Enter program details',
        'select_board_model':'Determine board model:',
        'related_board_model':'Based on the board model',
        'related_board_name':'Based on the board name',
        'board_name':'board name:',
        'insert_new_program':'New program',

    },
    'design_program':{
        'text':'Text',
        'newsTicker':'NewsTicker',
        'image':'Image',
        'video':'Video',
        'website':'Website',
        'shape':'Geometric Shapes',
        'prayTimes':'PrayTimes',
        'stream':'Live Stream',
        'settings':'Settings',
        'insert_text':'Add text',
        'insert_subscribe':'Add newsTicker',
        'direction_rtl':'Subtitle direction from right to left',
        'direction_ltr':'Subtitle direction from left to right',
        'placeholder_newsTicker':'Enter subtitle',
        'update_subscribe_text':'Update subtitle',
        'font_style':'Font style',
        'color_style':'Color',
        'font_color':'Change text color',
        'highlight_text':'Highlight text',
        'richBox_style':'Alignment',
        'align_left':'Left align',
        'align_center':'Center align',
        'align_right':'Right align',
        'insert_image':'Add image',
        'insert_video':'Add video',
        'url_website':'url',
        'load_website':'Show website',
        'placeholder_url_website':'Enter the URL',
        'change_color':'Change color',
        'background_color':'Background color',
        'border_color':'Border color',
        'prayTime_setting':'Pray time settings',
        'province':'Province',
        'city':'City',
        'show_location':'Location',
        'morning_time':'fajr',
        'sunset_time':'Sunset',
        'sunrise_time':'Sunrise',
        'maghreb_time':'Maghrib ',
        'noon_time':'Dhuhr',
        'evening_time':'Isha',
        'asr_time':'Asr',
        'midnight_time':'Midnight',
        'insert_pray_time':'Add pray time',
        'update_pray_time': 'Update pray time',
        'date_time':'Time / Date',
        'show_time':'Show Time',
        'show_date':'Show Date',
        'background_image':'Background image',
        'opacity':'Background resolution',
        'change_layers':'Change layer',
        'highest_layer': 'The top layer',
        'lowest_layer': 'The lowest layer',
        'bring_up_layer':'A higher layer',
        'bring_down_layer':'A lower layer',
        'magnify':'Zoom',
        'exist_object':'Available objects',
        'duplicate':'duplicate',
        'save':'save',
        'delete_all':'delete all',
        'redo':'redo',
        'undo':'undo',
        'preview':'preview',
        'media':'Insert',
        'exit':'Cancel',
        'insert_media':'Add saved data',
        'insert_shop':'Add from store',
        'delete_background':'Remove background',
        'delete_object':'(delete: shortcut key) Delete the selected object'
    },
    'add_user':{
        'insert_user':'Add user',
        'username':'Username:',
        'password':'Password:',
        'password_placeholder':'Enter password',
        'username_placeholder': 'Enter username',
        'name':'Name:',
        'name_placeholder': 'Enter name',
        'last_name':'last name:',
        'last_name_placeholder': 'Enter last name',
        'number':'Mobile number:',
        'number_placeholder': 'Enter mobile number',
        'email':'Email:',
        'email_placeholder': 'Enter email',
        'expiration':'Expiration date:',
        'date':'Date',
        'status':'Status',
        'access_board':'User authorized boards:',
        'permission':'Permission',
        'save':'Save'
    },
    'assign_user':{
        'insert_user':'Add user',
        'username':'Username:',
        'username_placeholder': 'Enter username',
        'user_permission':'User access to the board',
        'users':'Users :',
        'user_placeholder':'Select users',
        'board':'Boards :',
        'board_placeholder':' Select board',
        'save':'Save',
        'list_user':'User List',
        'order':'order',
        'usernames':'username',
        'full_name':'full name',
        'permission':'Access to boards',
        'actions':'actions',
        'edit':'edit',
        'delete':'delete',
        'list_board':'Boards List',
        'board_name':'Board name',
        'serial_board':'Board serial',
        'board_model':' Board model',
        'permissions':'Permissions',
        'show_access_board':'Show board access',
        'show_access_user':'Show user access',
        'access_board':'User access to the board',
        'access_user':'User access',
        'mobile':'mobile',
        'popup_edit':{
            'exit':'Cancel',
            'user':'User:',
            'board':'Board:',
            'board_placeholder':'select board',
            'edit':'Edit',
            'user_placeholder':'select user'
        }
    },
    'edit_user':{
        'edit_user':'Edit user',
        'name':'Name:',
        'name_placeholder': 'Enter name',
        'last_name':'Last name:',
        'last_name_placeholder': 'Enter last name',
        'number':'Mobile number:',
        'number_placeholder': 'Enter mobile number',
        'email':'Email:',
        'email_placeholder': 'Enter email',
        'expiration':' Expiration date:',
        'date':'Date',
        'status':'Status',
        'access_board':'User authorized boards:',
        'permission':'Permissions',
        'save':'Save',
        'active':'active',
        'inactive':'passive',
        'cancel':'Cancel'
    },
    'setting':{
        'users':'Users',
        'management_users':'Users management',
        'license':'License',
    },
    'user_management':{
        'exit':'cancel',
        'user_info':'User information',
        'full_name':'Full name:',
        'username':'Username:',
        'mobile':'Mobile number:',
        'email':'Email:',
        'expiration':'Expiration date:',
        'status':'Status:',
        'access_board_user':'User authorized boards:',
        'board':'Board:',
        'user_permissions':'Permissions',
        'order':'order',
        'all':'all',
        'active':'active',
        'inactive':'passive',
        'action':'action',
        'details':'details',
        'delete':'delete',
        'edit':'edit',
        'insert_users':'Add user',
    },
    'license_customer':{
        'insert_license':'Add license',
        'customer_name': 'Customer Name:',
        'license':'License:',
        'placeholder_customerNAme': 'Enter customer name',
        'placeholder_license':'Enter license',
        'save': 'Save' ,
        'details_license':'License details',
        'title': 'Title',
        'status':'Status',
    },
    'management_license':{
        'title_management_license':'Add license',
        'volume': 'volume',
        'user_number':'number of users',
        'board_number':'The number of boards',
        'volume_placeholder':'Enter the dedicated volume in megabytes',
        'user_number_placeholder':'Enter the number of users',
        'board_number_placeholder':'Enter the number of boards',
        'insert':'Create',
        'order':'order',
        'license':'license',
        'expiration':'expiration date',
        'customer':'customer',
        'action':'actions',
    }
}
